<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="costverifications" v-model:filters="filters1" v-model:selection="selectedRow"
                    :metaKeySelection="false" :filters="filters1" filterDisplay="menu" dataKey="id"
                    :globalFilterFields="['associationtypeno']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading" :paginator="true" :lazy="true"
                    :rows="pagesize" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:30%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入单号模糊查询" style="width:100%"
                                    @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink" #body="slotProps">
                            <Button :label="slotProps.data.associationtypeno"
                                @click="opencostverification(slotProps.data,false)" class="p-button-link" />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading"
                :saveBeforeMethod="saveBefore" :currentRow="currentRow" :childNewDataConfig="childNewDataConfig"
                :closeParentMehod="closeMaximizable" :freshListMethod="freshList" :childTableFooter="childTableFooter"
                :childDelDataConfig="childDelDataConfig" :dropOtherConfig="dropOtherConfig"
                :CellEditCompleteMethod="cellEditComplete" :loadDataAfterMethod="loadDataAfter"
                :childExpandePageConfig="childExpandeConfig" :pageJsonConfig="pageJsonConfig" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="saveData" :loading="butLoading"
                    autofocus />
                <Button v-if="!readOnly" label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="选择核销费用" @hide="verificationDialogClose" v-model:visible="showverificationDialog"
        :style="{width: '80vw'}" :maximizable="true" :modal="true">
        <div class="grid">
            <div class="flex flex-column col-12">
                <DataTable :value="waitverifications" v-model:selection="selectedwaitverificationRow"
                    :metaKeySelection="false" dataKey="id" selectionMode="single" scrollable scrollHeight="310px"
                    responsiveLayout="scroll" :loading="loading" :paginator="true" :lazy="true" rows="50"
                    :totalRecords="totalwaitverifications" :first="firstwaitverification"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listwaitverificationage($event)" @row-unselect="waitverificationunselect"
                    @row-select="waitverificationselect">
                    <template #header>
                        <div class="grid">
                            <div class="flex flex-column col-3">
                                <label for="verificationtype">核销类型</label>
                                <Dropdown v-model="selectedverificationtype" id="verificationtype"
                                    :options="verificationtypeDict" @change="verificationtypedropchange"
                                    optionLabel="name" optionValue="code" showClear="true">
                                </Dropdown>
                            </div>
                            <div class="flex flex-column col-3">
                                <label for="verificationstatus">核销状态</label>
                                <Dropdown v-model="selectedverificationstatus" id="verificationstatus"
                                    :options="verificationstatusDict" optionLabel="name" optionValue="code"
                                    showClear="true">
                                </Dropdown>
                            </div>
                            <div class="flex flex-column col-4">
                                <label for="no">单号</label>
                                <InputText id="no" v-model="verificationno" type="text" />
                            </div>
                            <div class="flex flex-column col-2">
                                <label for="queryverificationbut">&nbsp;</label>
                                <Button id="queryverificationbut" label="查询" icon="pi pi-search"
                                    @click="queryverificationData" :loading="butLoading" />
                            </div>
                        </div>
                    </template>
                    <Column selectionMode="single" frozen bodyStyle="width:3em;flex:0;" headerStyle="width:3em;flex:0;">
                    </Column>
                    <Column v-for="col of waitverificationcols" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :header="col.header" :key="col.field" :frozen="col.frozen">
                    </Column>
                </DataTable>
            </div>
        </div>
        <div class="grid">
            <div class="flex flex-column col-3">
                <label for="associationtypeno">核销单据编号</label>
                <InputText id="associationtypeno" v-model="currentverificationData.associationtypeno" readonly
                    type="text" />
            </div>
            <div class="flex flex-column col-3">
                <label for="costamount">费用金额</label>
                <InputText id="costamount" v-model="currentverificationData.costamount" readonly type="text" />
            </div>
            <div class="flex flex-column col-3">
                <label for="currencyname">币种</label>
                <InputText id="currencyname" v-model="currentverificationData.currencyname" readonly type="text" />
            </div>
            <div class="flex flex-column col-3">
                <label for="verificationamount">核销金额</label>
                <InputText id="verificationamount" v-model="currentverificationData.verificationamount" type="text" />
            </div>
            <div class="flex flex-column col-3">
                <label for="costtypename">费用类型</label>
                <InputText id="costtypename" v-model="currentverificationData.costtypename" readonly type="text" />
            </div>
            <div class="flex flex-column col-3">
                <label for="paytime">付款时间</label>
                <Calendar :showTime="false" id="paytime" v-model="currentverificationData.paytime" hourFormat="24"
                    dateFormat="yy-mm-dd" />
            </div>
            <div class="flex flex-column col-3">
                <label for="remark">备注</label>
                <InputText id="remark" v-model="currentverificationData.remark" type="text" />
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeverificationData" />
                <Button label="核销" icon="pi pi-check" @click="saveverificationData" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="数据导出" v-model:visible="importExcelDisplay" :style="{width: '30vw'}" :maximizable="false"
        :modal="true">
        <div v-if="!startimport" class="grid">
            <div class="col-8">
                <div class="text-left">
                    <Calendar placeholder="开始时间(收货日期)" v-model="startReceiptDate" dateFormat="yy-mm-dd" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Calendar placeholder="截至时间(收货日期)" v-model="endReceiptDate" dateFormat="yy-mm-dd" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div class="col-4">
                <div class="text-right">
                    <Button label="导出" icon="pi pi-external-link" @click="costnoverificationexportStart" />
                </div>
            </div>
        </div>
        <div v-if="startimport" class="grid p-fluid mt-3">
            <div v-if="importFinish" class="field col-12 md:col-12">
                <Button label="数据处理完成,请点击下载" @click="getExcleFilePatch" class="p-button-link" />
            </div>
            <div v-if="!importFinish" class="field col-12 md:col-12">
                <ProgressBar mode="indeterminate" style="height: .5em" />
            </div>
            <div v-if="!importFinish" class="field col-12 md:col-12">
                <label>数据处理中....</label>
            </div>
        </div>
    </Dialog>
</template>
<style type="text/css">
    .oneline {
        width: 170px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import {
        FilterMatchMode,
    } from 'primevue/api';
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '794';
            const listcolumns = ref();
            const usermoduleauth = [];
            return {
                confirm,
                currentMenuId,
                listcolumns,
                usermoduleauth
            };
        },
        data() {
            return {
                startReceiptDate: '',
                endReceiptDate: '',
                startimport: false,
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                importExcelDisplay: false,
                importFinish: false,
                excelFilePatch: '',
                verificationno: '',
                selectedverificationstatus: 0,
                showverificationDialog: false,
                waitverifications: [],
                waitverificationcols: [],
                selectedwaitverificationRow: {},
                totalwaitverifications: 0,
                firstwaitverification: 1,
                selectedverificationtype: 1,
                currentverificationData: {
                    id: '',
                    associationtypeno: '',
                    costamount: 0,
                    currencyname: '',
                    verificationamount: 0,
                    costtypename: '',
                    paytime: '',
                    associationid: '',
                    associationtype: '',
                    remark: '',
                    status: 1,
                },
                verificationstatusDict: [{
                    name: '未核销',
                    code: 0
                }, {
                    name: '已核销',
                    code: 1
                }],
                verificationtypeDict: [{
                    name: '货款',
                    code: 1
                }, {
                    name: '出运费用',
                    code: 2
                }, {
                    name: '入库费用',
                    code: 3
                }],
                butLoading: false,
                recordsubject: '',
                readOnly: false,
                displayMaximizable: false,
                displayQueryPlan: false,
                position: 'bottomleft',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                flowId: '',
                startStateId: '',
                currentState: '',
                flowhistoryJson: null,
                currentRow: {
                    bindpage: 'costverification',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                selectedRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                listTitle: '财务核销',
                selectedKey: {
                    794: true
                },
                childNewDataConfig: {
                    paydetail: false,
                    files: true,
                },
                childDelDataConfig: {
                    paydetail: true,
                },
                childTableFooter: {
                    paydetail: {
                        datano: '合计',
                        paymoney: 0,
                        invoiceamount: 0,
                    },
                },
                dropOtherConfig: {},
                currencyDict: [],
                pageJsonConfig: {},
                expandedKey: null,
                childExpandeConfig: {},
                costverifications: [],
                loading: true,
                nodes: [],
                items: [{
                    label: '财务操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                        label: '新建费用付款',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            window.location.href = '/#/crmfinancial/expensepay?newexpensepay=1';
                        }
                    }, {
                        separator: true
                    }, {
                        label: '新建费用核销',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            this.createcostverification();
                        }
                    }, {
                        separator: true
                    }, {
                        label: '新建对方收款账户',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            window.location.href = '/#/crmfinancial/collectionaccount?newdata=1';
                        }
                    }, {
                        separator: true
                    }, {
                        label: '新建我方付款账户',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            window.location.href = '/#/crmfinancial/paymentaccount?newdata=1';
                        }
                    }, {
                        separator: true
                    }, {
                        label: '新建款项名称',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            window.location.href = '/#/crmfinancial/paymentitem?newdata=1';
                        }
                    }, {
                        separator: true
                    }, {
                        label: '未核销数据导出',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            this.costnoverificationexport();
                        }
                    }]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getcostverification(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.initFilters1();
                    this.loadMenuTree();
                    this.loadUserAuth();
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            setloadDictFromCache(v_currencyDict) {
                this.currencyDict = v_currencyDict;
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('770', this.currentMenuId, this.$route, this
                    .loadData, this.setlistTitleFun, this.setloadMenuTree);
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '770',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.usermoduleauth = jsonData.data.usermoduleauthlist;

                        var createexpensepayflag = this.$route.query['newcostverification'];
                        if (createexpensepayflag) {
                            this.createcostverification();
                        }
                    }
                });
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '财务核销', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: []
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'associationtypeno',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{costverificationlist(where:" + JSON.stringify(listwhere) +
                    "){id associationtypeno costamount verificationamount paytime costtypename currencyname creater createtime}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.costverifications = jsonData.data.costverificationlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            clearcurrentverificationData() {
                this.currentverificationData['id'] = '';
                this.currentverificationData['associationtypeno'] = '';
                this.currentverificationData['costamount'] = 0;
                this.currentverificationData['currencyname'] = '';
                this.currentverificationData['verificationamount'] = 0;
                this.currentverificationData['costtypename'] = '';
                this.currentverificationData['paytime'] = '';
                this.currentverificationData['associationid'] = '';
                this.currentverificationData['associationtype'] = '';
                this.currentverificationData['remark'] = '';
                this.currentverificationData['status'] = 1;
            },
            createcostverification() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'NewCostVerification')) {
                    MessageTip.warnmsg('请联系管理员开通新建财务核销权限');
                    return;
                }
                this.selectedverificationtype = 1;
                this.selectedverificationstatus = 0;
                this.verificationno = '';
                this.selectedwaitverificationRow = {};
                this.queryverificationData();
                this.showverificationDialog = true;
            },
            opencostverification(v_data, v_isCopy) {
                this.recordsubject = v_data.itemname;
                this.readOnly = true;
                this.currentState = '';
                this.currentRow = {
                    isCopy: v_isCopy,
                    bindpage: 'costverification',
                    id: v_data.id,
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: '',
                };
                this.displayMaximizable = true;
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData, v_noshowelements) {
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                console.log(v_noshowelements);
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            saveBefore(v_data) {
                console.log('saveBefore:');
                console.log(v_data);
                return v_data;
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
                v_alonechildelements, v_elements) {
                console.log('cellEditCompleteMethod..');
                console.log(tablename);
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);
                console.log(v_childelements);
                console.log(v_alonechildelements);
                console.log(v_elements);
            },
            saveData() {},
            submitData() {},
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            },
            listwaitverificationage(event) {
                var page = event.page;
                if (this.selectedverificationtype == 1) {
                    this.loadWaitPurchaseVerification(page + 1);
                }
                if (this.selectedverificationtype == 2) {
                    this.loadWaitShipMentVerification(page + 1);
                }
                if (this.selectedverificationtype == 3) {
                    this.loadWaitInStorageVerification(page + 1);
                }
            },
            verificationtypedropchange() {
                this.selectedverificationstatus = 0;
                this.verificationno = '';
                this.queryverificationData();
            },
            closeverificationData() {
                this.showverificationDialog = false;
            },
            waitverificationunselect() {
                this.clearcurrentverificationData();
            },
            waitverificationselect(event) {
                var data = event.data;
                console.log('waitverificationselect....');
                console.log(this.selectedverificationtype);
                if (this.selectedverificationtype == 1) {
                    this.currentverificationData['id'] = '';
                    this.currentverificationData['associationtypeno'] = data['shipmentno'];
                    this.currentverificationData['costamount'] = data['costamount'];
                    this.currentverificationData['currencyname'] = data['purchasecurrencyname'];
                    this.currentverificationData['verificationamount'] = data['costamount'];
                    this.currentverificationData['costtypename'] = '货款';
                    this.currentverificationData['paytime'] = '';
                    this.currentverificationData['associationid'] = data['id'];
                    this.currentverificationData['associationtype'] = '1';
                    this.currentverificationData['remark'] = '';
                    this.currentverificationData['status'] = 1;
                }
                if (this.selectedverificationtype == 2) {
                    this.currentverificationData['id'] = '';
                    this.currentverificationData['associationtypeno'] = data['shipmentno'];
                    this.currentverificationData['costamount'] = data['costamount'];
                    this.currentverificationData['currencyname'] = data['currencyname'];
                    this.currentverificationData['verificationamount'] = data['costamount'];
                    this.currentverificationData['costtypename'] = data['costtypename'];
                    this.currentverificationData['paytime'] = '';
                    this.currentverificationData['associationid'] = data['id'];
                    this.currentverificationData['associationtype'] = '2';
                    this.currentverificationData['remark'] = '';
                    this.currentverificationData['status'] = 1;
                }
                if (this.selectedverificationtype == 3) {
                    this.currentverificationData['id'] = '';
                    this.currentverificationData['associationtypeno'] = data['instorageorderno'];
                    this.currentverificationData['costamount'] = data['costamount'];
                    this.currentverificationData['currencyname'] = data['currencyname'];
                    this.currentverificationData['verificationamount'] = data['costamount'];
                    this.currentverificationData['costtypename'] = data['costtypename'];
                    this.currentverificationData['paytime'] = '';
                    this.currentverificationData['associationid'] = data['id'];
                    this.currentverificationData['associationtype'] = '3';
                    this.currentverificationData['remark'] = '';
                    this.currentverificationData['status'] = 1;
                }
            },
            saveverificationData() {
                if (this.currentverificationData['associationtype'] && (this.currentverificationData[
                            'associationtype'] == '1' || this.currentverificationData['associationtype'] == '2' || this
                        .currentverificationData['associationtype'] == '3')) {
                    this.butLoading = true;
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "mutation{costverification(o:" + JSON.stringify(this.currentverificationData) +
                        "){id}}"
                    ).then(res => {
                        this.butLoading = false;
                        if (res.errcode == "0") {
                            this.selectedwaitverificationRow = {};
                            this.queryverificationData();
                            this.loadData(1);
                            MessageTip.successmsg('核销成功');
                        }
                    });
                }
            },
            verificationDialogClose() {
                console.log('verificationDialogClose....');
                this.freshData();
            },
            freshData() {
                requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid")).post(
                    '/calf/plat/FreshCost?appkey=' + sessionStorage.getItem("appkey"), {}).then(
                    res => {
                        console.log(res);
                        if (res.data.errcode != "0") {
                            if (res.data.err)
                                MessageTip.warnmsg(res.data.err);
                            else
                                MessageTip.warnmsg(res.data);
                        }
                    });
            },
            loadWaitPurchaseVerification(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'verificationstatus',
                        value: this.selectedverificationstatus + '',
                        operation: '='
                    }, {
                        name: 'shipmentno',
                        value: this.verificationno,
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{waitpurchaseverificationlist(where:" + JSON.stringify(listwhere) +
                    "){id shipmentno purchaseno mpn sku quantity unitprice costamount purchasecurrencyname verificationamount verificationstatus}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.waitverifications = jsonData.data.waitpurchaseverificationlist;
                        this.totalwaitverifications = jsonData.page.allrecord;
                        this.firstwaitverification = 50 * (jsonData.page.pageindex - 1);
                    }
                });
            },
            loadWaitShipMentVerification(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'verificationstatus',
                        value: this.selectedverificationstatus + '',
                        operation: '='
                    }, {
                        name: 'shipmentno',
                        value: this.verificationno,
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{waitshipmentverificationlist(where:" + JSON.stringify(listwhere) +
                    "){id shipmentno costamount costtypename currencyname verificationamount verificationstatus}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.waitverifications = jsonData.data.waitshipmentverificationlist;
                        this.totalwaitverifications = jsonData.page.allrecord;
                        this.firstwaitverification = 50 * (jsonData.page.pageindex - 1);
                    }
                });
            },
            loadWaitInStorageVerification(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'verificationstatus',
                        value: this.selectedverificationstatus + '',
                        operation: '='
                    }, {
                        name: 'instorageorderno',
                        value: this.verificationno,
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{waitinstorageverificationlist(where:" + JSON.stringify(listwhere) +
                    "){id instorageorderno costamount costtypename currencyname verificationamount verificationstatus}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.waitverifications = jsonData.data.waitinstorageverificationlist;
                        this.totalwaitverifications = jsonData.page.allrecord;
                        this.firstwaitverification = 50 * (jsonData.page.pageindex - 1);
                    }
                });
            },
            queryverificationData() {
                this.clearcurrentverificationData();
                if (this.selectedverificationtype == 1) {
                    this.selectedwaitverificationRow = {};
                    this.waitverifications = [];
                    this.waitverificationcols = [{
                        "field": "shipmentno",
                        "header": "出运单号",
                        "bodyStyle": "min-width:150px;left:0px",
                        "headerStyle": "min-width:150px;left:0px",
                        "frozen": true,
                    }, {
                        "field": "purchaseno",
                        "header": "采购单号",
                        "bodyStyle": "min-width:150px;left:0px",
                        "headerStyle": "min-width:150px;left:0px",
                        "frozen": true,
                    }, {
                        "field": "mpn",
                        "header": "MPN",
                        "bodyStyle": "min-width:150px;left:0px",
                        "headerStyle": "min-width:150px;left:0px",
                        "frozen": true,
                    }, {
                        "field": "sku",
                        "header": "SKU",
                        "bodyStyle": "min-width:160px;left:0px",
                        "headerStyle": "min-width:160px;left:0px",
                        "frozen": true,
                    }, {
                        "field": "quantity",
                        "header": "数量",
                        "bodyStyle": "min-width:100px;left:0px",
                        "headerStyle": "min-width:100px;left:0px",
                        "frozen": false,
                    }, {
                        "field": "unitprice",
                        "header": "单价",
                        "bodyStyle": "min-width:80px;left:0px",
                        "headerStyle": "min-width:80px;left:0px",
                        "frozen": false,
                    }, {
                        "field": "costamount",
                        "header": "货款",
                        "bodyStyle": "min-width:140px;left:0px",
                        "headerStyle": "min-width:140px;left:0px",
                        "frozen": false,
                    }, {
                        "field": "purchasecurrencyname",
                        "header": "币种",
                        "bodyStyle": "min-width:80px;left:0px",
                        "headerStyle": "min-width:80px;left:0px",
                        "frozen": false,
                    }];
                    if (this.selectedverificationstatus == 1) {
                        this.waitverificationcols.push({
                            "field": "verificationamount",
                            "header": "核销金额",
                            "bodyStyle": "min-width:150px;left:0px",
                            "headerStyle": "min-width:150px;left:0px",
                            "frozen": false,
                        });
                    }
                    this.loadWaitPurchaseVerification(1);
                }
                if (this.selectedverificationtype == 2) {
                    this.selectedwaitverificationRow = {};
                    this.waitverifications = [];
                    this.waitverificationcols = [{
                        "field": "shipmentno",
                        "header": "出运单号",
                        "bodyStyle": "min-width:160px;left:0px",
                        "headerStyle": "min-width:160px;left:0px",
                        "frozen": true,
                    }, {
                        "field": "costamount",
                        "header": "费用",
                        "bodyStyle": "min-width:160px;left:0px",
                        "headerStyle": "min-width:160px;left:0px",
                        "frozen": false,
                    }, {
                        "field": "currencyname",
                        "header": "币种",
                        "bodyStyle": "min-width:80px;left:0px",
                        "headerStyle": "min-width:80px;left:0px",
                        "frozen": false,
                    }, {
                        "field": "costtypename",
                        "header": "费用类型",
                        "bodyStyle": "min-width:160px;left:0px",
                        "headerStyle": "min-width:160px;left:0px",
                        "frozen": false,
                    }];
                    if (this.selectedverificationstatus == 1) {
                        this.waitverificationcols.push({
                            "field": "verificationamount",
                            "header": "核销金额",
                            "bodyStyle": "min-width:150px;left:0px",
                            "headerStyle": "min-width:150px;left:0px",
                            "frozen": false,
                        });
                    }
                    this.loadWaitShipMentVerification(1);
                }
                if (this.selectedverificationtype == 3) {
                    this.selectedwaitverificationRow = {};
                    this.waitverifications = [];
                    this.waitverificationcols = [{
                        "field": "instorageorderno",
                        "header": "入库单号",
                        "bodyStyle": "min-width:160px;left:0px",
                        "headerStyle": "min-width:160px;left:0px",
                        "frozen": true,
                    }, {
                        "field": "costamount",
                        "header": "费用",
                        "bodyStyle": "min-width:160px;left:0px",
                        "headerStyle": "min-width:160px;left:0px",
                        "frozen": false,
                    }, {
                        "field": "currencyname",
                        "header": "币种",
                        "bodyStyle": "min-width:80px;left:0px",
                        "headerStyle": "min-width:80px;left:0px",
                        "frozen": false,
                    }, {
                        "field": "costtypename",
                        "header": "费用类型",
                        "bodyStyle": "min-width:160px;left:0px",
                        "headerStyle": "min-width:160px;left:0px",
                        "frozen": false,
                    }];
                    if (this.selectedverificationstatus == 1) {
                        this.waitverificationcols.push({
                            "field": "verificationamount",
                            "header": "核销金额",
                            "bodyStyle": "min-width:150px;left:0px",
                            "headerStyle": "min-width:150px;left:0px",
                            "frozen": false,
                        });
                    }
                    this.loadWaitInStorageVerification(1);
                }
            },
            costnoverificationexport() {
                this.excelFilePatch = '';
                this.startReceiptDate = '';
                this.endReceiptDate = '';
                this.importFinish = false;
                this.importExcelDisplay = true;
                this.startimport = false;
            },
            costnoverificationexportStart() {
                this.startimport = true;
                var startReceiptDateStr = '';
                var endReceiptDateStr = '';
                if (this.startReceiptDate != '') {
                    startReceiptDateStr = CommonJs.formatDate('DateString', this.startReceiptDate);
                }
                if (this.endReceiptDate != '') {
                    endReceiptDateStr = CommonJs.formatDate('DateString', this.endReceiptDate);
                }
                requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid")).post(
                        '/calf/plat/CostNoVerificationExport?appkey=' + sessionStorage.getItem("appkey"), {
                            StartTime: startReceiptDateStr,
                            EndTime: endReceiptDateStr
                        })
                    .then(
                        res => {
                            console.log(res);
                            if (res.data.errcode == "0") {
                                var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                                console.log('costnoverificationexport:');
                                console.log(retObj);
                                this.excelFilePatch = retObj.filepatch;
                                this.importFinish = true;
                            } else {
                                this.importExcelDisplay = false;
                                MessageTip.warnmsg(res.data);
                            }
                        });
            },
            getExcleFilePatch() {
                location.href = '/calf/plat/file/' + this.excelFilePatch + '?appkey=' + sessionStorage.getItem(
                    "appkey");
                return;
            }
        },
        components: {
            NewUniversalPage,
            'AppFooter': AppFooter,
        }
    }
</script>